import React, { useState, useRef } from 'react';
import Snackbar from './snackbar/snackbar';
import axios from 'axios';
import { useNavigate, withRouter } from 'react-router-dom';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function Contact() {
	let history = useNavigate();
	const snackbarRef = useRef(null);
	const [hover, setHover] = useState(false);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [subject, setSubject] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('name', fname);
		formData.append('email', email);
		formData.append('subject', subject);
		formData.append('message', message);

		axios
			.post('https://api.akallivilla.gr/api/mailing/contactus', formData)
			.then((response) => {
				snackbarRef.current.show();
			})
			.catch(function (error) {
				history('/fail');
			});
	};
	return (
		<div
			style={{
				backgroundImage:
					'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url(images/DJI_0798.jpg)',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center',
				backgroundAttachment: 'fixed',
				height: '700px',
				fontFamily: 'Inconsolata',
			}}
		>
			<Snackbar
				ref={snackbarRef}
				message="Mail has been sent"
				type={SnackbarType.success}
			/>
			<h2
				style={{
					color: 'white',
					textAlign: 'center',
					marginTop: 80,
					paddingTop: 40,
					fontWeight: 500,
					fontSize: 32,
				}}
			>
				Contact Us
			</h2>
			<form className="py-16">
				<div>
					<input
						type="text"
						placeholder="Full Name"
						className="mr-auto ml-auto"
						onChange={(e) => setFname(e.target.value)}
						style={{
							marginBottom: 24,
							width: '50%',
							height: 55,
							display: 'block',
							background: 'rgba(0,0,0,.4)',
							borderRadius: '12px',
							color: 'white',
							opacity: 1,
							padding: '12px',
							fontWeight: 600,
							fontStyle: 'italic',
						}}
					/>
				</div>

				<div
					className="it"
					style={{
						placeItems: 'center',
					}}
				>
					<input
						type="text"
						placeholder="Email"
						onChange={(e) => setEmail(e.target.value)}
						className="mr-auto ml-auto"
						style={{
							marginBottom: 24,
							width: '50%',
							height: 55,
							display: 'block',
							background: 'rgba(0,0,0,.4)',
							borderRadius: '12px',
							color: 'white',
							opacity: 1,
							padding: '12px',
							fontWeight: 600,
							fontStyle: 'italic',
						}}
					/>
				</div>
				<div>
					<input
						onChange={(e) => setSubject(e.target.value)}
						type="text"
						placeholder="Subject"
						className="mr-auto ml-auto"
						style={{
							marginBottom: 24,
							width: '50%',
							height: 55,
							display: 'block',
							background: 'rgba(0,0,0,.4)',
							borderRadius: '12px',
							color: 'white',
							opacity: 1,
							padding: '12px',
							fontWeight: 600,
							fontStyle: 'italic',
						}}
					/>
				</div>
				<div>
					<textarea
						onChange={(e) => setMessage(e.target.value)}
						type="text"
						rows="5"
						cols="60"
						placeholder="Write your Message..."
						className="mr-auto ml-auto"
						multiple={true}
						style={{
							marginBottom: 24,
							width: '50%',
							height: 125,
							display: 'block',
							background: 'rgba(0,0,0,.4)',
							borderRadius: '12px',
							color: 'white',
							opacity: 1,
							padding: '12px',
							fontWeight: 600,
							fontStyle: 'italic',
						}}
					/>
				</div>
				<button
					onMouseEnter={() => setHover(!hover)}
					onMouseLeave={() => setHover(!hover)}
					onClick={(e) => handleSubmit(e)}
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						display: 'block',
						backgroundColor: 'white',
						color: hover ? 'black' : 'white',
						padding: 16,
						borderRadius: 18,
						fontSize: 16,
						fontWeight: 600,
						background:
							'linear-gradient(to left, #282928 50%, lightblue 50%) right',
						backgroundSize: '200%',
						transition: '.5s ease-out',
						backgroundPosition: hover ? 'left' : 'right',
					}}
				>
					Send Message
				</button>
			</form>
		</div>
	);
}
