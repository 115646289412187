import React from 'react';
import Card from './card';

export default function AfterHero() {
	return (
		<>
			<div
				style={{
					height: 400,
				}}
			>
				<h2
					className="pt-36 text-center text-2xl"
					style={{ fontFamily: 'Inconsolata' }}
				>
					Akalli Villas
				</h2>
				<p
					className="mt-4"
					style={{
						maxWidth: 700,
						marginLeft: 'auto',
						marginRight: 'auto',
						textAlign: 'center',
						fontFamily: 'Inconsolata',
					}}
				>
					In our villa you can enjoy leisure vacations far away from
					the hustle and bustle of the city life, and of course from a
					small distance by the sea, circa 3 km and 8 km from the
					picturesque Rethymno. Villa Acalle is an ideal choice for a
					big group of people who wish to enjoy together their
					vacations, since it can host up to 11 people. Our residence
					combines the natural beauty of the landscape with our
					distinguished esthetic. Come to meet us, we ‘ll be here
					waiting for you!
				</p>
			</div>
			<div className="grid md:grid-cols-2 grid-cols-1 h-full mt-16 z-10">
				<Card
					image="images/WDL01236.jpg"
					paragraph="Here you will find the genuine Cretan hospitality, as you can stroll around in the nature, meet the customs and the traditions of the region and the Cretan life."
				/>
				<Card
					image="images/WL200371.jpg"
					paragraph="Here you will find the genuine Cretan hospitality, as you can stroll around in the nature, meet the customs and the traditions of the region and the Cretan life."
				/>
			</div>
		</>
	);
}
