import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import './css/hero.css';
import Snackbar from '../components/snackbar/snackbar';

const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function BoxSearch() {
	const snackbarRef = useRef(null);
	let history = useNavigate();
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const [visitors, setVisitors] = useState(1);
	const [contact, setContact] = useState(false);
	const [excludedDates, setExcludedDates] = useState([]);
	const minusButton = () => {
		if (visitors <= 1) {
			setVisitors(1);
		} else {
			setVisitors(visitors - 1);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios
				.get(`https://api.akallivilla.gr/api/bookings/closedDates`)
				.then((res) => {
					if (res.data) {
						return res.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
			let dates = [];
			for (let i = 0; i < response.length; i++) {
				const start = new Date(response[i].start);
				const end = new Date(response[i].end);
				let moving = start;
				while (moving <= new Date(end)) {
					dates.push(new Date(moving));
					moving.setUTCDate(moving.getUTCDate() + 1);
				}
			}
			setExcludedDates([...dates]);
		};
		fetchData();
	}, []);
	const validCheck = () => {
		let dates = [];
		let first = new Date(startDate);
		while (first <= dateRange[1]) {
			dates.push(new Date(first));
			first.setUTCDate(first.getUTCDate() + 1);
		}
		let flag = 0;
		dates.map((date) => {
			for (let i = 0; i < excludedDates.length; i++) {
				if (
					date.getFullYear() === excludedDates[i].getFullYear() &&
					date.getDate() === excludedDates[i].getDate() &&
					date.getMonth() === excludedDates[i].getMonth()
				) {
					flag = 1;

					return flag;
				}
			}
			return flag;
		});
		return flag;
	};
	const submitDates = () => {
		if (dateRange[0] && dateRange[1]) {
			const flag = validCheck();
			if (flag === 1) {
				snackbarRef.current.show();
			} else if (dateRange[0] && dateRange[1] && visitors) {
				history('/room/available', {
					state: {
						start: startDate,
						end: endDate,
						person: visitors,
					},
				});
			} else {
				snackbarRef.current.show();
			}
		} else {
			snackbarRef.current.show();
		}
	};
	
	return (
		<div className="md:grid md:grid-cols-4 grid grid-cols-1 h-full w-full flex-1">
			<div className="flex flex-col justify-center items-center gap-4 mt-2 mb-2">
				<h3 style={{ fontSize: '.6875rem', fontWeight: 700 }}>
					Rooms
				</h3>
				<select
					className="w-48 cursor-pointer"
					style={{
						border: '1px solid gray',
						borderRadius: 10,
						padding: 10,
					}}
				>
					<option value="1">Akallis room</option>
					<option value="2">Minoas room</option>
					<option value="3">Pasiphaes room</option>
					<option value="4">Agia Paraskevi room</option>
				</select>
			</div>
			<div className="flex flex-col justify-center items-center gap-4 mt-2 mb-2">
				<h3 style={{ fontSize: '.6875rem', fontWeight: 700 }}>
					CHECK-IN/CHECK-OUT
				</h3>
				{excludedDates.length === 0 ? (
					<DatePicker
						selectsRange={true}
						startDate={startDate}
						endDate={endDate}
						placeholderText="Select Dates"
						onChange={(update) => {
							setDateRange(update);
						}}
						dateFormat="dd/MM/yyyy"
						minDate={new Date()}
						withPortal
						className="w-48 cursor-pointer "
						showMonthDropdown
						useShortMonthInDropdown
					/>
				) : (
					<DatePicker
						selectsRange={true}
						startDate={startDate}
						endDate={endDate}
						dateFormat="dd/MM/yyyy"
						placeholderText="Select Dates"
						onChange={(update) => {
							setDateRange(update);
						}}
						excludeDates={excludedDates}
						highlightDates={excludedDates}
						minDate={new Date()}
						withPortal
						className="w-52 cursor-pointer border-2 rounded-lg px-2 p-2"
						showMonthDropdown
						useShortMonthInDropdown
					/>
				)}
				<Snackbar
					ref={snackbarRef}
					message="Please insert valid dates!"
					type={SnackbarType.fail}
				/>
			</div>
			<div className="flex flex-col justify-center items-center gap-4 ">
				<h3 style={{ fontWeight: 700 }}>Visitors</h3>
				<div className="grid grid-cols-3 w-full place-items-center">
					<div>
						<button
							style={{
								border: '1px solid gray',
								width: 50,
								height: 50,
								fontSize: 22,
							}}
							onClick={minusButton}
							className='flex justify-center items-center'
						>
							-
						</button>
					</div>
					<div>{visitors}</div>
					<div>
						<button
							style={{
								border: '1px solid gray',
								width: 50,
								height: 50,
								fontSize: 22,
							}}
							onClick={() => setVisitors(visitors + 1)}
						>
							+
						</button>
					</div>
				</div>
			</div>
			<div className="grid place-items-center">
				<button
					onMouseEnter={() => setContact(true)}
					to="/room/available"
					state={{
						start: dateRange[0],
						end: dateRange[1],
						person: visitors,
					}}
					onClick={() => submitDates()}
					onMouseLeave={() => setContact(false)}
					className="md:h-[50px] md:w-[200px] h-[50px]"
					style={{
						border: '1px solid gray',
						borderRadius: 10,
						fontSize: 16,
						fontWeight: 700,
						textAlign: 'center',
						color:  'white',
						background:
							'linear-gradient(to left, #282928 50%, #a89132 50%) right',
						backgroundSize: '200%',
						transition: '.5s ease-out',
						backgroundPosition: contact ? 'left' : 'right',
					}}
				>
					Check Availability
				</button>
			</div>
		</div>
	);
}
