import React from 'react';

export default function Map() {
	return (
		<div className="mapouter" style={{ marginTop: 128 }}>
			<div className="gmap_canvas">
				<iframe
					title="test"
					width="80%"
					height="400"
					src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Agia%20Paraskeui%2074100%20Akalli+(My%20Business%20Name)&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
					id="gmap_canvas"
					frameBorder="0"
					scrolling="no"
					marginHeight="0"
					marginWidth="0"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				/>

				<a href="https://www.embedgooglemap.net"></a>
			</div>
		</div>
	);
}
