import React from 'react';
import './mainSection.css';
export default function MainSection() {
	return (
		<div className="mt-32 text-center">
			<div className="mb-6">
				<h2 className="borderLine">
					<span
						className="text-2xl "
						style={{ fontFamily: 'Inconsolata' }}
					>
						Covid-19 Protocols
					</span>
				</h2>
			</div>
			<p
				className="max-w-[700px] mx-auto"
				style={{ fontFamily: 'Inconsolata' }}
			>
				Akalli Villa is committed to following the rigorous 5-step
				cleaning process. A set of standards we developed in
				collaboration with experts for the COVID-19 pandemic period and
				beyond.
			</p>
			<div className="grid md:grid-cols-2 grid-cols-1 2xl:px-64 px-0 ">
				<div className="xl:px-16 px-0">
					<ol
						style={{
							textAlign: 'start',
							listStyleType: 'circle',
							padding: 32,
							fontFamily: 'Inconsolata',
							fontSize: 16,
							fontWeight: 500,
						}}
					>
						<li>Fighting germs on surfaces</li>
						<li>
							We follow the process of fighting germs on every
							surface that visitors often touch, even the knobs
						</li>
						<li>Use of approved products</li>

						<li>Τhorough cleaning</li>
					</ol>
				</div>
				<div className="xl:px-16 px-0">
					<ol
						style={{
							textAlign: 'start',
							listStyleType: 'circle',
							padding: 32,
							fontFamily: 'Inconsolata',
							fontSize: 16,
							fontWeight: 500,
						}}
					>
						<li>
							We use cleansers approved by health experts, such as
							disinfectants with at least 70% alcohol
						</li>
						<li>
							We look at the extensive checklists as I clean each
							room
						</li>
						<li>
							We help prevent cross-contamination by wearing a
							mask and gloves
						</li>
						<li>We wash the bed linen at a high temperature</li>
					</ol>
				</div>
			</div>
			<div className="mb-6 mt-16">
				<h2 className="borderLine">
					<span
						className="text-2xl "
						style={{ fontFamily: 'Inconsolata' }}
					>
						Location
					</span>
				</h2>
			</div>
			<div className="grid lg:grid-cols-2 grid-cols-1 xl:px-64 px-0 mt-8 md:mt-2">
				<div
					className="xl:px-4 px-4 mt-0"
					style={{
						fontFamily: 'Inconsolata',
						textAlign: 'start',
						lineHeight: 2,
					}}
				>
					<p>
						Villa Acalle is a restored stone-built residence located
						in the village of Agia Paraskevi at Rethymno, one of the
						prefectures in Crete. Villa Acalle provides to its
						guests a panoramic view combining in a unique way the
						sea and the mountains of the island. From your one hand
						you can observe the Mount Ida, also called Psiloritis,
						the biggest mountain of Crete, and from the other the
						magic sea spreading until the Akrotiri of Chania (in
						Greek it literally means promontory).
					</p>
				</div>
				<div>
					<img
						className="mt-8 md:mt-0 md:w-full w-1/2 md:mx-0 mx-auto"
						src="../images/DJI_0795.jpg"
						style={{ borderRadius: 20 }}
					/>
				</div>
			</div>
			<div className="mb-6 mt-16 ">
				<h2 className="borderLine">
					<span
						className="text-2xl "
						style={{ fontFamily: 'Inconsolata' }}
					>
						Our History
					</span>
				</h2>
			</div>
			<div className="grid lg:grid-cols-2 grid-cols-1 xl:px-64  mt-8 md:mt-0">
				<div>
					<img
						src="../images/DJI_0798.jpg"
						style={{ borderRadius: 20 }}
						className="mt-8 md:mt-0 md:w-full w-1/2 md:mx-0 mx-auto"
					/>
				</div>
				<div
					className="xl:pl-4 px-4  xl:mt-0 mt-0"
					style={{
						fontFamily: 'Inconsolata',
						textAlign: 'start',
						lineHeight: 2,
					}}
				>
					<p>
						The villa is named after the princess of Crete,
						Acacallis or shortly Acalle, the daughter of Minos and
						Pasiphae. The residence is a Venetian building dating
						from the time when Crete was under the Venice’s rule
						from 13th until the middle of 17th century. For that
						reason, our guests can notice the impact of the
						Venetians in the architecture of the residence. Later,
						during the Ottoman rule the residence was under the
						power of the local bey at the time. Later, on the 20th
						century, Crete was occupied by the Germans and more
						specifically the residence was commanded by German
						officers due to its strategic location and it has been
						used as an observation post, so they could monitor the
						surrounding area.
					</p>
				</div>
			</div>
			<div>
				<h2 className="borderLine mt-16">
					<span
						className="text-2xl "
						style={{ fontFamily: 'Inconsolata' }}
					>
						Cancellation Policy
					</span>
				</h2>
				<p
					className="max-w-[700px] mx-auto"
					style={{ fontFamily: 'Inconsolata' }}
				>
					Travelers who cancel at least 14 days before check- in will
					get back 100% of the amount they've paid. Travelers who
					cancel at least 7 days before check- in will get 50% of the
					amount they've paid.
				</p>
			</div>
		</div>
	);
}
