import React, { useState } from 'react';

import { useStepperContext } from '../../context/stepperContext';

export default function PaymentMethod() {
	const { payment, setPayment, userData } = useStepperContext();

	return (
		<div className="mb-16">
			<h2 className="text-gray-600 mt-16 mb-8">Payment Method</h2>
			{/* <h3 className="text-2xl text-gray-500">
				Payment powered by{' '}
				<span
					style={{
						background: 'url(/images/viva.png) 0 0 no-repeat',
						backgroundSize: 'contain',
						width: 48,
						height: 32,
						display: 'inline-block',
					}}
				></span>
				VivaWallet
			</h3> */}
			<select
				name="drinks"
				className="bg-cyan-600 w-1/2 text-base h-8"
				onChange={(e) => setPayment(e.target.value)}
				value={payment}
			>
				<option defaultValue value="Bank">
					Bank Transfer
				</option>
				{/* <option value="Card30">Card 30%</option>
				<option value="Card">Card Full Price</option> */}
			</select>
		</div>
	);
}
