import Home from './pages/home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AvailableRoom from './pages/availableRoom';
import Fail from './pages/fail';
import About from './pages/about';
import Contact from './pages/contact';
import Gallery from './pages/gallery';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Success from './pages/success';
import Error from './pages/error';
import Apes from './pages/apes';
function App() {
	return (
		<Router>
			<meta name="csrf-token" content="{{ csrf_token() }}" />
			<div className="App">
				<CookieConsent>
					This website uses the default React Application's cookies.
				</CookieConsent>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route
						exact
						path="/room/available"
						element={<AvailableRoom />}
					/>
					<Route exact path="/fail" element={<Error />} />
					<Route exact path="/about" element={<About />} />
					<Route exact path="/gallery" element={<Gallery />} />
					<Route exact path="/contact" element={<Contact />} />
					<Route exact path="/success" element={<Success />} />
					<Route exact path="/error" element={<Error />} />
					<Route exact path="/payLink" element={<Apes />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
