import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./navbar.css";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";

export default function Navbar({ height }) {
  const [logo, setLogo] = useState(false);
  const [contact, setContact] = useState(false);
  return (
    <div
      className="grid grid-rows-3 gap-2 absolute top-0 left-0 w-full"
      style={{ zIndex: 1, height: height ? height : 100 }}
    >
      <div className="md:row-span-2 row-span-1  h-full">
        <div className="flex flex-row justify-between lg:px-0 pr-12 items-center">
          <div
            className="h-full"
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 70,
            }}
          >
            <Sidebar />
          </div>
          <div className="md:col-span-1 col-span-2">
            <Link to="/">
              <img
                src="../images/logo.jpg"
                alt="logo"
                onMouseEnter={() => setLogo(true)}
                onMouseLeave={() => setLogo(false)}
                width={60}
                style={{
                  borderRadius: "100%",
                  marginTop: 10,
                  marginLeft: "auto",
                  marginRight: "auto",
                  transform: logo ? "rotate(720deg)" : "rotateZ(0deg)",
                  transition: "transform 1s ease",
                  cursor: "pointer",
                  zIndex: 0,
                }}
              />
            </Link>
          </div>
          <div
            style={{ textAlign: "end", height: "100%" }}
            className="md:block hidden"
          >
            <div
              style={{
                marginTop: 28,
                marginRight: 40,
              }}
            >
              <Link
                onMouseEnter={() => setContact(true)}
                onMouseLeave={() => setContact(false)}
                to="/contact"
                style={{
                  border: "1px solid gray",
                  height: 30,
                  width: 150,
                  borderRadius: 12,
                  textAlign: "center",
                  display: "inline-block",
                  fontSize: 16,
                  color: "white",
                  background:
                    "linear-gradient(to left, #282928 50%, #a89132 50%) right",
                  backgroundSize: "200%",
                  transition: ".5s ease-out",
                  backgroundPosition: contact ? "left" : "right",
                }}
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
        <div className="col-span-3">
          {/*Bottom border line*/}
          <div
            className="mx-auto h-4"
            style={{
              borderBottom: "2px solid #cfcfcf",
              width: "80%",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
