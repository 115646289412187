import React, { useState } from 'react';
import { useStepperContext } from '../../context/stepperContext';
export default function Preview({ setDisplay,activeRoom }) {
	const {
		userData,
		roomId,
		check_in,
		check_out,
		person,
		price,
		setPrice,
		payment,
		check,
		setCheck,
	} = useStepperContext();

	const changeDateType = (date) => {
		return new Date(date).toISOString().substring(0, 10);
	};
	const calculateCost = (date1, date2, room, person) => {
		const check_in = new Date(date1);
		const check_out = new Date(date2);
		const oneDay = 1000 * 60 * 60 * 24;
		const diffInTime = check_in.getTime() - check_out.getTime();
		const diffInDays = -Math.round(diffInTime / oneDay);

		let price_per_night = activeRoom.price;
		if (person > 6) {
			for (let counter = 7; counter <= person; counter++) {
				price_per_night += 25;
			}
		}
		let price = price_per_night * diffInDays;
		setPrice(price);
		return price;
	};
	return (
		<>
			<div>
				<h2>Preview Your Booking</h2>
				<div className="grid md:grid-cols-2 grid-cols-1 text-gray-600 gap-3 md:mb-0 mb-12">
					<div>
						<h5 style={{ borderBottom: '1px solid black' }}>
							Your Details
						</h5>
						<div className="text-lg ">
							<h6>First Name: {userData.first_name}</h6>
							<h6>Last Name:{userData.last_name}</h6>
							<h6>Email: {userData.email}</h6>
							<h6>Phone: {userData.phone}</h6>
						</div>
					</div>
					<div>
						<h5 style={{ borderBottom: '1px solid black' }}>
							Booking Details
						</h5>
						<div className="text-lg">
							<h6>Room Name:{roomId.room.name}</h6>
							<h6>
								Check In: {changeDateType(check_in.check_in)}
							</h6>
							<h6>
								Check Out: {changeDateType(check_out.check_out)}
							</h6>
							<h6>Person:{person.persons}</h6>
							<h6>
								Total Price:
								{calculateCost(
									check_in.check_in,
									check_out.check_out,
								 
									person.persons
								)}
								&euro;
							</h6>
							<h6>
								Payment Method:{' '}
								{payment === 'Card30'
									? 'Card 30%'
									: payment === 'Card'
									? 'Card full price'
									: payment}
							</h6>
							{payment !== 'Bank' ? (
								<>
									<input
										type="checkbox"
										onChange={(e) => setCheck(!check)}
										checked={check}
									/>
									<label className="ml-2">
										Accept the terms of{' '}
										<button
											onClick={() => setDisplay(true)}
										>
											cancelation policy
										</button>
									</label>
								</>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
