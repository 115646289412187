import React from 'react';
import {
	FaPlane,
	FaPhone,
	FaMailBulk,
	FaFacebook,
	FaInstagram,
	FaGoogle,
	FaLinkedin,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
export default function Footer() {
	return (
		<div
			className="grid lg:grid-cols-2 grid-cols-1 lg:h-[500px] h-[800px] lg:px-32 px-4 gap-4 mt-16"
			style={{
				backgroundImage:
					'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url(../images/WDL01270.jpg)',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center',
				backgroundAttachment: 'fixed',
			}}
		>
			<div
				className="h-full"
				style={{
					textAlign: 'center',
					marginTop: 46,
					marginLeft: 'auto',
					marginRight: 'auto',
					color: 'white',
					fontSize: 32,
					maxWidth: '300px',
				}}
			>
				<img
					src="../images/logo.jpg"
					alt="logo"
					width={100}
					style={{
						borderRadius: '100%',
						marginLeft: 'auto',
						marginRight: 'auto',
					}}
				/>

				<div
					className=" "
					style={{
						display: 'flex',
						marginTop: 12,
						fontWeight: 600,
						position: 'relative',
						textAlign: 'center',
						marginLeft: 'auto',
						marginRight: 'auto',
					}}
				>
					<FaPlane
						style={{
							fontSize: 12,
							color: '#d2a85e',
							marginRight: 12,
							marginTop: 8,
						}}
					/>
					<span style={{ fontSize: 16 }}>
						{' '}
						Agia Paraskevi, Rethymno, 74100
					</span>
				</div>
				<div
					className=" "
					style={{
						display: 'flex',
						marginTop: 12,
						fontWeight: 600,
						position: 'relative',
					}}
				>
					<FaPhone
						style={{
							fontSize: 12,
							color: '#d2a85e',
							marginRight: 12,
							marginTop: 8,
						}}
					/>
					<span style={{ fontSize: 16 }}>697 935 0530</span>
				</div>
				<div
					className=" "
					style={{
						display: 'flex',
						marginTop: 12,
						fontWeight: 600,
						position: 'relative',
					}}
				>
					<FaMailBulk
						style={{
							fontSize: 12,
							color: '#d2a85e',
							marginRight: 12,
							marginTop: 8,
						}}
					/>
					<span style={{ fontSize: 16 }}>
						akallivilla.rethimno@gmail.com
					</span>
				</div>
			</div>

			<div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
				<div
					style={{
						marginTop: 58,
						marginLeft: 'auto',
						marginRight: 'auto',
						color: 'white',
						fontSize: 32,
					}}
				>
					<h3>Quick Link</h3>
					<ol
						style={{
							fontSize: 18,
							marginTop: 24,
						}}
					>
						<li
							style={{
								marginTop: 8,
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Link to="/about">About</Link>
						</li>
						<li
							style={{
								marginTop: 8,
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Link to="/gallery">Gallery</Link>
						</li>
						<li
							style={{
								marginTop: 8,
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Link to="/contact">Contact</Link>
						</li>
					</ol>
					<div
						style={{
							display: 'flex',
							marginTop: 24,
							fontSize: 16,
						}}
					>
						<a href="https://www.facebook.com/akalli.villa">
							<FaFacebook style={{ marginRight: '12px' }} />
						</a>
						<a href="https://www.google.com/search?client=firefox-b-d&q=akalli+villa#lrd=0x149b0bf4a0a1687b:0xd83ec7e9b2877da5,1,,,">
							<FaGoogle style={{ marginRight: '12px' }} />
						</a>
						<a href="https://www.instagram.com/akalli.villa/">
							<FaInstagram style={{ marginRight: '12px' }} />
						</a>
					</div>
				</div>
			</div>
			<div
				className="lg:col-span-3"
				style={{
					width: '100%',
					bottom: '0',
					marginTop: 'auto',
					borderTop: '1px solid #e8e7e6',
				}}
			>
				<h3 style={{ color: 'white', textAlign: 'center' }}>
					Copyright ©2022 All rights reserved | This template is made
					by GreekFatCoders
				</h3>
			</div>
		</div>
	);
}
