import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const starting = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.1, type: "linear", opacity: { duration: 1 } },
  },
  exit: {
    opacity: 0,
    transition: { delay: 0.1, type: "linear", opacity: { duration: 1.4 } },
  },
};
const text = {
  initial: {
    opacity: 0,
  },
  hover: {
    opacity: 1,
    rotateY: 180,
    transition: {
      delay: 0.1,
      type: "linear",
      opacity: { duration: 1.4 },
      rotateY: { duration: 1 },
    },
  },
};
const variantMenu = {
  hidden: {
    x: "-100vh",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { delay: 0.1, type: "linear", y: { duration: 1 } },
  },
  exit: {
    x: "-100vh",
    transition: { delay: 0.1, type: "linear", y: { duration: 1 } },
  },
};
export default function Sidebar() {
  const [isOpen, setisOpen] = useState(false);
  const [menuHover, setMenuHover] = useState(false);
  const openModal = () => {
    setMenuHover(false);
    setisOpen(!isOpen);
  };
  return (
    <>
      <button
        style={{ cursor: "pointer",  width: 50 }}
        onMouseEnter={() => setMenuHover(true)}
        onMouseLeave={() => setMenuHover(false)}
        onClick={openModal}
        className={`z-40 ${isOpen?'fixed':"mt-4"}`}
      >
        <motion.div
          style={{
            backgroundColor: menuHover | isOpen ? "#a89132" : "#282928",
            width: "30px",
            height: "4px",
            display: "block",
            cursor: "pointer",
            transition:
              "width .2s ease 0s,left .2s ease 0s,background .2s ease 0s",
          }}
          animate={{
            y: isOpen ? 6 : 0,
            rotate: isOpen ? 60 : 0,
          }}
          transition={{
            delay: 0.1,
            y: { duration: 0.1 },
            rotate: { duration: 0.5 },
            default: { ease: "linear" },
          }}
        ></motion.div>
        <motion.div
          animate={{
            opacity: isOpen ? 0 : 1,
          }}
          transition={{
            delay: 0.1,
            opacity: { duration: 0.5 },

            default: { ease: "linear" },
          }}
          style={{
            width: "30px",
            display: "block",
            height: "4px",
            marginTop: 5,
            marginLeft: menuHover ? 0 : 10,
            backgroundColor: menuHover ? "#a89132" : "#282928",
            transition:
              "margin-left .2s ease 0s,left .2s ease 0s,background .2s ease 0s",
          }}
        ></motion.div>
        <motion.div
          animate={{
            y: isOpen ? -12 : 0,
            rotate: isOpen ? 120 : 0,
          }}
          transition={{
            delay: 0.1,
            y: { duration: 0.1 },
            rotate: { duration: 0.5 },
            default: { ease: "linear" },
          }}
          style={{
            position: "relative",
            width: "30px",
            height: "4px",
            marginTop: 5,
            display: "block",
            backgroundColor: menuHover | isOpen ? "#a89132" : "#282928",
            transition: "left .2s ease 0s,background .2s ease 0s",
            zIndex: -1,
          }}
        ></motion.div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={` ${
              isOpen ? "fixed z-30" : "absolute z-0"
            } w-full  h-screen top-0 left-0 grid grid-cols-3`}
            variants={starting}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {isOpen && (
              <motion.div
                className="bg-gray-500 h-full lg:col-span-1 col-span-3"
                variants={variantMenu}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <div className="h-full flex flex-col justify-center space-y-12 items-center bg-neutral-900 text-gray-100">
                  <Link to="/">
                    <h2 className="">Home</h2>
                  </Link>
                  <Link to="/about">
                    <h2 className="">About</h2>
                  </Link>
                  <Link to="/gallery">
                    <h2 className="">Gallery</h2>
                  </Link>
                  <Link to="/contact">
                    <h2 className="">Contact</h2>
                  </Link>

                  
                </div>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
