import React from 'react';
import Hero from '../components/contact/hero';
import Navbar from '../components/navbar';
import Map from '../components/map';
import Footer from '../components/footer';
export default function Contact() {
	return (
		<>
			<Navbar />
			<Hero />
			<div className="text-center">
				<h6
					className="mt-32 text-3xl -mb-24 "
					style={{ fontFamily: 'Inconsolata' }}
				>
					Locate Us
				</h6>
				<Map />
			</div>
			<Footer />
		</>
	);
}
