import React from 'react';
import MainSection from '../components/about/mainSection';
import Footer from '../components/footer';
import Hero from '../components/hero';
import Navbar from '../components/navbar';
export default function About() {
	return (
		<>
			<Navbar />
			<Hero
				title="Welcome to Akalli Villa"
				subtitle="Learn more about us"
			/>
			<MainSection />
			<Footer />
		</>
	);
}
