import React from 'react';

export default function BtnSlider({ direction, moveSlide }) {
	return (
		<div
			style={{
				height: '100%',
				width: '25%',
				left: direction === 'next' ? '75%' : '0%',
				position: 'absolute',
				cursor:
					direction === 'next'
						? 'url(../images/rightCursor.png),auto'
						: 'url(../images/leftCursor.png),auto',
			}}
			onClick={moveSlide}
		></div>
	);
}
