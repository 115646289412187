import React, { useState, useRef } from 'react';
import axios from 'axios';
import Snackbar from '../snackbar/snackbar';
import { useNavigate, withRouter } from 'react-router-dom';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function Hero() {
	const snackbarRef = useRef(null);
	let history = useNavigate();
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [subject, setSubject] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append('name', fname);
		formData.append('email', email);
		formData.append('subject', subject);
		formData.append('message', message);

		axios
			.post('https://api.akallivilla.gr/api/mailing/contactus', formData)
			.then((response) => {
				snackbarRef.current.show();
			})
			.catch(function (error) {
				history('/fail');
			});
	};
	return (
		<div
			style={{
				height: 1250,
				width: '100%',
				marginTop: -250,
				position: 'relative',
				zIndex: 0,
				backgroundImage:
					'linear-gradient(to bottom, rgba(0,0,0,0.1)  30%, rgba(0,0,0,1)),url("/images/WL200396.jpg")',
				backgroundSize: 'cover',
				backgroundPosition: 'center bottom',
				backgroundAttachment: 'fixed',
			}}
		>
			<Snackbar
				ref={snackbarRef}
				message="Mail has been sent"
				type={SnackbarType.success}
			/>
			<div className="centered left-1/2 md:text-4xl h-1/2 mt-32 md:px-32 px-2 py-8 bg-white text-2xl md:w-1/2 w-3/4 text-center  pb-2 shadow-xl rounded-2xl">
				<h6 className="text-gray-600">Contact</h6>
				<div className="grid grid-cols-1 mb-24 text-lg gap-4">
					<div className="w-full mx-auto  mt-8 ">
						<div className="bg-white my-2 p-1 mx-2 flex border border-gray-200 rounded h-12 ">
							<input
								onChange={(e) => setFname(e.target.value)}
								name="first_name"
								placeholder="Full Name"
								className="p-1 px-2 appearance-none outline-non w-full text-gray-800 "
							/>
						</div>
					</div>
					<div className="w-full mx-auto ">
						<div className="bg-white my-2 mx-2 p-1 flex border border-gray-200 rounded">
							<input
								onChange={(e) => setEmail(e.target.value)}
								name="email"
								placeholder="Email"
								className="p-1 px-2 appearance-none outline-non w-full text-gray-800"
							/>
						</div>
					</div>
					<div className="w-full mx-auto">
						<div className="bg-white my-2 p-1 mx-2 flex border border-gray-200 rounded">
							<input
								onChange={(e) => setSubject(e.target.value)}
								name="last_name"
								placeholder="Subject"
								className="p-1 px-2 appearance-none outline-non w-full text-gray-800"
							/>
						</div>
					</div>

					<div className="w-full mx-auto ">
						<div className="bg-white my-2 mx-2 p-1 flex border border-gray-200 rounded">
							<textarea
								onChange={(e) => setMessage(e.target.value)}
								name="message"
								rows={2}
								placeholder="Message"
								className="p-1 px-2 appearance-none outline-non w-full text-gray-800"
							/>
						</div>
					</div>
					<div>
						<button
							onClick={handleSubmit}
							className="text-gray-600 border-2 border-gray-400 rounded w-16 h-12 mt-12 hover:border-gray-800 hover:text-white transition ease-in-out duration-300 hover:bg-gray-800"
						>
							Send
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
