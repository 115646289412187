import React, { useState, useEffect } from 'react';
import GallerySection from '../components/gallerySection';
import Hero from '../components/hero';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import axios from 'axios';

export default function Gallery() {
	const [images, setImages] = useState([]);
	const fetchImagesGallery = async () => {
		const response = await axios
			.get('https://api.akallivilla.gr/api/galleries')
			.then((res) => {
				setImages(res.data);
			});
	};
	useEffect(() => {
		fetchImagesGallery();
	}, []);
	return (
		<>
			<Navbar />
			<Hero title="Gallery" subtitle="Watch the Photos" />
			<GallerySection title="Gallery Section" images={images} />
			<Footer />
		</>
	);
}
