import React, { useState } from "react";
import BoxSearch from "./boxSearch";
import "./css/hero.css";

export default function Hero({ title, subtitle }) {
  const [display, setDisplay] = useState(false);
  const [contact, setContact] = useState(false);

  return (
    <>
      <div
        id="myModal2"
        className="modal"
        style={{
          display: display ? "block" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="mx-auto block">
          <span className="close" onClick={() => setDisplay(false)}>
            &times;
          </span>
          <div className="h-screen w-full text-center py-8 flex justify-center items-center">
            <iframe
              width="960"
              height="540"
              src="https://tour.panoee.com/akalli-villa/6414174ed2b0a61c199d98d6"
              frameBorder="0"
              allowFullScreen
              className="mx-auto w-full h-full"
              title="360 tour"
            ></iframe>
          </div>
        </div>
      </div>

      <div
        style={{
          height: 900,
          width: "100%",
          marginTop: -133,
          position: "relative",
          zIndex: 0,
          backgroundImage:
            'linear-gradient(to bottom, rgba(0,0,0,0.1)  30%, rgba(0,0,0,1)),url("/images/WL200396.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          backgroundAttachment: "fixed",
        }}
      >
        {/* <br />
        <br /> */}
        <div className="relative md:absolute top-1/4 md:top-3/4 w-full flex justify-center">
          <a
          href="/files/TOURISMOS_WEB.pdf"
          target="_blank"
          rel="noreferrer"
          className="higher-z-index block mx-auto mt-8 text-base z-10 rounded-2xl border border-gray-200 p-4 md:p-6 hover:border-gray-400 transition-all duration-300"
        >
          <img src="/images/e-bannersEUERDF600X80.jpg" alt="EUERDF" className="max-w-full h-auto" />
        </a>
        </div>
        <div className="centered mx-auto lg:left-1/4 left-1/2 md:text-4xl text-2xl w-full text-center">
          <h1>{title}</h1>
          <h6 className="text-xl" style={{ textAlign: "center" }}>
            {subtitle}
          </h6>
          <button
            className="rounded-2xl border-1 p-2 mt-8 text-base"
            style={{
              background: "linear-gradient(to left, #282928 50%, #a89132 50%) right",
              backgroundSize: "200%",
              transition: ".5s ease-out",
              backgroundPosition: contact ? "left" : "right",
              fontFamily: "Inconsolata",
            }}
            onMouseEnter={() => setContact(true)}
            onMouseLeave={() => setContact(false)}
            onClick={() => setDisplay(true)}
          >
            Watch Villa 360&deg;
          </button>
        </div>

        <div className="flex justify-center items-center absolute md:top-3/4 top-3/4 w-full h-[125px]">
          <div
            className="flex justify-center items-center md:h-[125px] h-[300px] md:mt-96 md:w-[1000px] w-[300px]"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 3px 13px 0px rgba(0, 0, 0, 0.86)",
              borderRadius: 12,
            }}
          >
            <BoxSearch />
          </div>
        </div>
      </div>
    </>
  );
}