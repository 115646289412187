import React, { useState, useEffect } from 'react';
import ImageModal from './imageModal';
import './css/hero.css';
export default function GalleryPerDesc({ images }) {
	const [imgsLoaded, setImgsLoaded] = useState(false);
	useEffect(() => {
		const loadImage = (image) => {
			return new Promise((resolve, reject) => {
				const loadImg = new Image();
				loadImg.src = images.image_url + image;
				// wait 2 seconds to simulate loading time
				loadImg.onload = () =>
					setTimeout(() => {
						resolve(images.image_url + image);
					}, 2000);

				loadImg.onerror = (err) => reject(err);
			});
		};
		Promise.all(images.images.map((image) => loadImage(image)))
			.then(() => setImgsLoaded(true))

			.catch((err) => console.log('Failed to load images', err));
	}, []);
	console.log(images);
	return (
		<div>
			<h3
				className="text-2xl text-gray-600 mt-12"
				style={{ fontFamily: 'Inconsolata' }}
			>
				{images.description}
			</h3>
			<div
				className={`grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2  grid-cols-1 lg:px-32 px-4 mt-8 lg:gap-3 gap-1`}
			>
				{images.images.length ? (
					images.images.map((imag, index) => {
						return (
							<ImageModal
								image={imag}
								images={images}
								key={imag + index}
							/>
						);
					})
				) : (
					<h1>Loading images...</h1>
				)}
			</div>
		</div>
	);
}
