import React from 'react';
import AfterHero from '../components/afterHero';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Hero from '../components/hero';
import Map from '../components/map';
import MiniCarousel from '../components/miniCarousel';
import Navbar from '../components/navbar';

export default function Home() {
	return (
		<>
			<Navbar
				title="Welcome to Akalli Villas"
				subtitle="Rethymno's resort"
				book={true}
			/>
			<Hero
				title="Welcome to Akalli Villa"
				subtitle="Rethymno's Resort"
			/>
			<AfterHero />
			<MiniCarousel />
			<Contact />
			<Map />
			<Footer />
		</>
	);
}
