import React, { useRef, useState } from 'react';
import Hero from '../components/components_available/hero';
import { UseContextProvider } from '../context/stepperContext';
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import CancelPolicy from '../components/cancelPolicy';
import RoomInfo from '../components/RoomInfo';
export default function Apes() {
	const { state } = useLocation();
	const { url } = state;
	const [display, setDisplay] = useState(false);
	const firstItemRef = useRef(null);
	return (
		<>
			<Navbar height={100} />
			<UseContextProvider>
				<div
					style={{
						height: 1220,
						width: '100%',
						marginTop: -250,
						position: 'relative',
						zIndex: 0,
						backgroundImage:
							'linear-gradient(to bottom, rgba(0,0,0,0.1)  30%, rgba(0,0,0,1)),url("/images/WL200396.jpg")',
						backgroundSize: 'cover',
						backgroundPosition: 'center bottom',
						backgroundAttachment: 'fixed',
					}}
				>
					<div className="centered left-1/2 md:text-4xl h-1/2 md:mt-32 mt-16 bg-white text-xl w-full md:w-3/4 text-center  pb-2 shadow-xl rounded-2xl">
						<div className="container horizontal mt-1">
							<div className="md:mt-10 mt-14 md:p-10 p-0">
								<div className="container md:mt-12 mt-32">
									<div className="flex flex-col items-center">
										<div>
											<img
												src="../images/icons8-tick-96.png"
												alt="completed"
											/>
										</div>
										<div className="mt-3 text-xl font-semibold uppercase text-teal-300">
											Your Browser blocked the url
										</div>
										<div className="text-lg font-semibold text-gray-500">
											Go to this link in order to pay
										</div>
										<div className="text-lg font-semibold text-gray-500">
											<a href={url}>{url}</a>
										</div>

										<a
											className="mt-10"
											href="/user/dashboard"
										>
											<Link
												to="/"
												className="h-12 px-5 text-teal-400 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-teal-300 hover:text-white"
											>
												Close
											</Link>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<RoomInfo />
				<CancelPolicy ref={firstItemRef} setDisplay={setDisplay} />
			</UseContextProvider>
			<Footer style={{ marginTop: 0 }} />
		</>
	);
}
