import React, { useState, useEffect } from "react";
import { useStepperContext } from "../../context/stepperContext";
import axios from "axios";
import BtnSlider from "./btnSlider";
import "../css/slider.css";
export default function AvailableRoom({activeRoom,setActiveRoom}) {
  const [rooms, setRooms] = useState([]);
  const [images, setImages] = useState([]);
  const [slideIndex, setSlideIndex] = useState(1);
  
  const { roomId, setRoomId } = useStepperContext();
  const nextSlide = () => {
    if (slideIndex !== images.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === images.length) {
      setSlideIndex(1);
    }
  };
  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(images.length);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios
        .get(`https://api.akallivilla.gr/api/rooms`)
        .then((res) => {
          if (res.data.length > 0) {
            return res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setRooms([...response]);
      setActiveRoom(response[0]);
    };
    fetchData();
  }, []);
  console.log(rooms);
  console.log("active room", activeRoom);
  return (
    <>
      {rooms.length > 0
        ? rooms.map(
            (room) =>
              room.id === activeRoom.id && (
                <div className="grid grid-cols-1 md:grid-cols-2 mt-8 mb-8">
                  <div className=" text-black ">
                    <div className="border-b-1 border-black">
                      Room Name:{room.name}
                    </div>
                    <div className="text-xl">{room.description}</div>
                    <div className="text-base mt-6">
                      <h2>Max Visitors: {room.max_visitors}</h2>
                      <h2>Min Visitors: {room.min_visitors}</h2>
                      <h2>Price per night: {room.price}&euro;</h2>
                    </div>
                  </div>
                  <div className="h-64 flex items-center justify-center align-center">
                    <div>
                      <BtnSlider
                        moveSlide={() =>
                          rooms.map((room, index) => {
                            if (room.id === activeRoom.id && index - 1 >= 0) {
                              setActiveRoom(rooms[index - 1]);
                            }
                          })
                        }
                        direction={"prev"}
                      />
                      <img
                        src={`${room.image_url}${room.main_image}`}
                        style={{
                          borderRadius: 12,
                          marginLeft: "auto",
                          display: "block",
                          marginRight: "auto",
                          width: 500,
                        }}
                        width={500}
                        className="md:h-[333px] h-[200px]"
						onClick={()=>document
							.getElementById('roomInfo')
							.scrollIntoView({ behavior: 'smooth' })}
                      />
                    </div>
                  </div>
                  <BtnSlider
                    moveSlide={() =>
                      rooms.map((room, index) => {
                        if (
                          room.id === activeRoom.id &&
                          index + 1 < rooms.length
                        ) {
                          setActiveRoom(rooms[index + 1]);
                        }
                      })
                    }
                    direction={"next"}
                  />
                </div>
              )
          )
        : ""}
    </>
  );
}
