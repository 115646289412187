import React, { useEffect, useState, useRef } from 'react';

export default function Stepper({ steps, currentStep }) {
	const [newStep, setNewStep] = useState([]);
	const stepRef = useRef();
	const updateStep = (stepNumber, steps) => {
		const newSteps = [...steps];
		let count = 0;
		while (count < newSteps.length) {
			if (count === stepNumber) {
				newSteps[count] = {
					...newSteps[count],
					highlighted: true,
					selected: true,
					completed: true,
				};
				count++;
			} else if (count < stepNumber) {
				newSteps[count] = {
					...newSteps[count],
					highlighted: false,
					selected: true,
					completed: true,
				};
				count++;
			} else {
				newSteps[count] = {
					...newSteps[count],
					highlighted: false,
					selected: false,
					completed: false,
				};
				count++;
			}
		}
		return newSteps;
	};
	useEffect(() => {
		const stepsState = steps.map((step, index) =>
			Object.assign(
				{},
				{
					description: step,
					completed: false,
					highlighted: index === 0 ? true : false,
					selected: index === 0 ? true : false,
				}
			)
		);
		stepRef.current = stepsState;
		const current = updateStep(currentStep - 1, stepRef.current);
		setNewStep(current);
	}, [steps, currentStep]);
	const displaySteps = newStep.map((step, index) => {
		return (
			<div
				key={index}
				className={`${
					index !== newStep.length - 1
						? `w-full flex items-center`
						: `flex items-center`
				}`}
			>
				<div className="relative flex flex-col items-center text-black">
					<div
						className={`rounded-full transition duration-500 ease-in-out border-2  h-8 w-8 flex items-center justify-center py-3 ${
							step.selected
								? 'bg-teal-300 text-white font-bold border-2 border-teal-300'
								: 'border-black'
						}`}
					>
						{step.completed ? (
							<span className="text-white font-bold text-sm border-2 border-teal-300">
								&#10003;
							</span>
						) : (
							<h3 className="text-lg">{index + 1}</h3>
						)}
					</div>
					<div
						className={`absolute top-0 text-center mt-16 w-32 md:px-0 px-4 md:text-base text-sm font-medium uppercase ${
							step.highlighted
								? 'text-black font-extrabold'
								: 'text-gray-600'
						}`}
					>
						{step.description}
					</div>
				</div>
				<div
					className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
						step.completed ? 'border-teal-500' : 'border-gray-300'
					}`}
				></div>
			</div>
		);
	});
	return (
		<div className="mx-0 md:px-8 py-4 px-12 flex justify-between items-center">
			{displaySteps}
		</div>
	);
}
