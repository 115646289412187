import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useStepperContext } from '../context/stepperContext';
import ImageModal from './imageModal';
import RoomGallery from './roomGallery';
export default function RoomInfo({ ref,activeRoom,setActiveRoom }) {
	const [rooms, setRooms] = useState([]);
	const [images, setImages] = useState([]);
	const [slideIndex, setSlideIndex] = useState(1);
	const { roomId, setRoomId } = useStepperContext();
 
	useEffect(() => {
		const fetchData = async () => {
			const response = await axios
				.get(`https://api.akallivilla.gr/api/rooms`)
				.then((res) => {
					if (res.data.length > 0) {
						return res.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
			setRooms([...response]);
				
			setRoomId({ roomId, ['room']: response });
		};
		fetchData();
	}, []);

	return (
		<>

			{activeRoom &&
				<div id="roomInfo">
					<h3
						className="text-2xl text-gray-600 mt-12 mb-12"
						style={{
							fontFamily: 'Inconsolata',
							textAlign: 'center',
						}}
					>
						{activeRoom.name}
					</h3>
					<div
						className={`grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2  grid-cols-1 lg:px-32 px-4 mt-8 lg:gap-3 gap-1`}
					>
						{activeRoom ? (
							activeRoom.secondary_images.map((imag, index) => {
								return (
									<RoomGallery
										image={imag}
										images={activeRoom.image_url}
										key={imag + index}
									/>
								);
							})
						) : (
							<h1>Loading images...</h1>
						)}
					</div>
				</div>
} 
		</>
	);
}
