import React, { useState, useEffect } from 'react';
import './css/imageModal.css';
import LazyLoad from 'react-lazyload';
export default function ImageModal({ image, images }) {
	const [display, setDisplay] = useState(false);
	const [samples, setSample] = useState([]);
	const [activeImage, setActiveImage] = useState('');
	const [size, setSize] = useState({
		x: window.innerWidth,
		y: window.innerHeight,
	});
	useEffect(() => {
		window.onresize = updateSize;
		const getRandomImages = () => {
			let imgs = [];
			let limit = 10;
			if (size.x < 600) limit = 5;

			for (let i = 0; i < limit; i++) {
				const randomNumber =
					Math.floor(Math.random() * (images.images.length - 0)) + 0;
				imgs.push(images.images[randomNumber]);
			}
			setSample([...imgs]);
		};

		getRandomImages();
	}, []);
	const updateSize = () =>
		setSize({
			x: window.innerWidth,
			y: window.innerHeight,
		});
	const activateModal = () => {
		setDisplay(true);
		setActiveImage(image);
	};

	return (
		<>
			<img
				id="myImg"
				src={`${images.image_url}${image}`}
				onClick={activateModal}
				style={{ height: 350, width: '100%' }}
				alt={`active ${image}`}
				className=" md:px-0 sm:px-16 px-0 "
			/>

			<div
				id="myModal"
				className="modal"
				style={{ display: display ? 'flex' : 'none' }}
			>
				<span className="close" onClick={() => setDisplay(false)}>
					&times;
				</span>
				<img
					id="img01"
					style={{ overflowY: 'hidden' }}
					src={`${images.image_url}${activeImage}`}
					alt={`modal Open ${activeImage}`}
					className="modal-content h-[400px] mx-auto rounded-2xl w-[500px] lg:w-[900px] lg:h-[700px] md:w-[800px] md:h-[500px] 2xl:h-[600px] md:px-0 px-8 sm:h-[500px] sm:w-[600px] my-auto md:my-auto block"
				/>
			</div>
		</>
	);
}
