import { Link } from 'react-router-dom';

export default function Final() {
	return (
		<div className="container md:mt-12 mt-32">
			<div className="flex flex-col items-center">
				<div>
					<img src="../images/icons8-tick-96.png" alt="completed" />
				</div>
				<div className="mt-3 text-xl font-semibold uppercase text-teal-300">
					Thank you!
				</div>
				<div className="text-lg font-semibold text-gray-500">
					Your Book has been created.
				</div>
				<div className="text-lg font-semibold text-gray-500">
					An email has been sent to you!
				</div>
				<div className="text-lg font-semibold text-gray-500">
					Check your spam folder too !
				</div>
				<a className="mt-10" href="/user/dashboard">
					<Link
						to="/"
						className="h-12 px-5 text-teal-400 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-teal-300 hover:text-white"
					>
						Close
					</Link>
				</a>
			</div>
		</div>
	);
}
