import { createContext, useContext, useState } from 'react';

const StepperContext = createContext({
	userData: '',
	setUserData: null,
	room_id: '',
	setRoomId: null,
	check_in: '',
	setCheckIn: null,
	check_out: '',
	setCheckOut: null,
	person: '',
	setPerson: null,
	price: '',
	setPrice: null,
	payment: 'Bank',
	setPayment: null,
	check: false,
	setCheck: null,
});

export function UseContextProvider({ children }) {
	const [userData, setUserData] = useState('');
	const [roomId, setRoomId] = useState('');
	const [check_in, setCheckIn] = useState('');
	const [check_out, setCheckOut] = useState('');
	const [person, setPerson] = useState('');
	const [price, setPrice] = useState('');
	const [payment, setPayment] = useState('Bank');
	const [check, setCheck] = useState(false);
	return (
		<StepperContext.Provider
			value={{
				userData,
				setUserData,
				roomId,
				setRoomId,
				check_in,
				setCheckIn,
				check_out,
				setCheckOut,
				person,
				setPerson,
				price,
				setPrice,
				payment,
				setPayment,
				check,
				setCheck,
			}}
		>
			{children}
		</StepperContext.Provider>
	);
}

export function useStepperContext() {
	const {
		userData,
		setUserData,
		roomId,
		setRoomId,
		check_in,
		setCheckIn,
		check_out,
		setCheckOut,
		person,
		setPerson,
		price,
		setPrice,
		payment,
		setPayment,
		check,
		setCheck,
	} = useContext(StepperContext);

	return {
		userData,
		setUserData,
		roomId,
		setRoomId,
		check_in,
		setCheckIn,
		check_out,
		setCheckOut,
		person,
		setPerson,
		price,
		setPrice,
		payment,
		setPayment,
		check,
		setCheck,
	};
}
