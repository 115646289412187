import React from 'react';

export default function CancelPolicy() {
	return (
		<div>
			<h2 className="borderLine mt-16">
				<span
					className="text-2xl text-center"
					style={{ fontFamily: 'Inconsolata' }}
				>
					Cancellation Policy
				</span>
			</h2>
			<p
				className="max-w-[700px] mx-auto mt-4 mb-16 text-center"
				style={{ fontFamily: 'Inconsolata' }}
			>
				Travelers who cancel at least 14 days before check-in will get
				back 100% of the amount they've paid. Travelers who cancel at
				least 7 days before check-in will get 50% of the amount they've
				paid.
			</p>
		</div>
	);
}
