import { useContext, useState } from 'react';
import { useStepperContext } from '../../context/stepperContext';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
export default function Account() {
	const { userData, setUserData, roomId } = useStepperContext();
	const [value, setValue] = useState();
	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
		console.log(userData);
	};
	const updatePhone = (value) => {
		setUserData({ ...userData, ['phone']: value });
	};
	console.log(value);
	return (
		<div className="grid md:grid-cols-2 grid-cols-1 md:mb-0 mb-12 ">
			<div className="md:w-full w-2/3 md:mx-2 mx-auto flex-1 ">
				<div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase">
					First Name
				</div>
				<div className="bg-white my-2 p-1 mx-2 flex border border-gray-200 rounded">
					<input
						onChange={handleChange}
						defaultValue={userData['first_name'] || ''}
						name="first_name"
						placeholder="First Name"
						className="p-1 px-2 appearance-none outline-non w-full text-gray-800 "
						required
					/>
				</div>
			</div>
			<div className="md:w-full w-2/3 md:mx-2 mx-auto flex-1">
				<div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase">
					Last Name
				</div>
				<div className="bg-white my-2 p-1 mx-2 flex border border-gray-200 rounded">
					<input
						onChange={handleChange}
						defaultValue={userData['last_name'] || ''}
						name="last_name"
						placeholder="Last Name"
						className="p-1 px-2 appearance-none outline-non w-full text-gray-800"
						required
					/>
				</div>
			</div>
			<div className="md:w-full w-2/3 md:mx-2 mx-auto flex-1">
				<div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase">
					Email
				</div>
				<div className="bg-white my-2 mx-2 p-1 flex border border-gray-200 rounded">
					<input
						onChange={handleChange}
						defaultValue={userData['email'] || ''}
						name="email"
						placeholder="Email"
						className="p-1 px-2 appearance-none outline-non w-full text-gray-800"
						required
					/>
				</div>
			</div>
			<div className="md:w-full w-2/3 md:mx-2 mx-auto flex-1 -mb-2">
				<div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase">
					Phone Number
				</div>
				<div className="bg-white my-2 mx-2 p-1 flex border border-gray-200 rounded">
					{/* <input
						onChange={handleChange}
						defaultValue={userData['phone'] || ''}
						name="phone"
						placeholder="Phone Number"
						className="p-1 px-2 appearance-none outline-non w-full text-gray-800"
						required
					/> */}
					<PhoneInput
						placeholder="Enter phone number"
						name="phone"
						defaultValue={value}
						onChange={updatePhone}
						className="p-1 px-2 appearance-none outline-non w-full text-gray-800"
					/>
				</div>
			</div>
		</div>
	);
}
