import React, { useRef, useState } from 'react';
import Hero from '../components/components_available/hero';
import { UseContextProvider } from '../context/stepperContext';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import CancelPolicy from '../components/cancelPolicy';
import RoomInfo from '../components/RoomInfo';
export default function AvailableRoom() {
	const location = useLocation();
	const { start, end, person } = location.state;
	const [display, setDisplay] = useState(false);
	const [activeRoom, setActiveRoom] = useState(0);
	const firstItemRef = useRef(null);
	return (
		<>
			<Navbar height={100} />
			<UseContextProvider>
				{display ? (
					<div
						id="myModal"
						class="modal"
						style={{
							display: display ? 'block' : 'none',
							width: '100%',
							color: 'white',
						}}
					>
						<span class="close" onClick={() => setDisplay(false)}>
							&times;
						</span>
						<div>
							<h2 className="borderLine mt-16">
								<span
									className="text-2xl text-center"
									style={{
										fontFamily: 'Inconsolata',
									}}
								>
									Cancellation Policy
								</span>
							</h2>
							<p
								className="max-w-[700px] mx-auto mt-4 mb-16 text-center"
								style={{ fontFamily: 'Inconsolata' }}
							>
								Travelers who cancel at least 14 days before
								check-in will get back 100% of the amount
								they've paid. Travelers who cancel at least 7
								days before check-in will get 50% of the amount
								they've paid.
							</p>
						</div>
					</div>
				) : (
					''
				)}
				<Hero
					start={start}
					end={end}
					persons={person}
					setDisplay={setDisplay}
					activeRoom={activeRoom}
					setActiveRoom={setActiveRoom}
				/>
				<RoomInfo activeRoom={activeRoom} setActiveRoom={setActiveRoom}/>
				<CancelPolicy ref={firstItemRef} setDisplay={setDisplay} />
			</UseContextProvider>
			<Footer style={{ marginTop: 0 }} />
		</>
	);
}
