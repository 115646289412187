import React, { useState } from 'react';
import { useStepperContext } from '../../context/stepperContext';
import axios from 'axios';
import { useNavigate, withRouter } from 'react-router-dom';
import { parsePhoneNumber } from 'react-phone-number-input';

export default function StepperControl({ handleClick, currentStep, steps,activeRoom }) {
	let history = useNavigate();
	const {
		userData,
 
		check_in,
		check_out,
		person,
		price,
		payment,
		check,
	} = useStepperContext();
	const redirectFunction = () => {
		if (check === false) {
			return false;
		} else {
			console.log('checked');
		}
	};
	const submitForm = async () => {
		if (payment === 'Bank') {
			var formData = new FormData();
			formData.append('check_in', check_in.check_in);
			formData.append('check_out', check_out.check_out);
			formData.append('guests', person.persons);
			formData.append('room_id', activeRoom.id);
			formData.append('price', price);
			formData.append('customer_first_name', userData.first_name);
			formData.append('customer_last_name', userData.last_name);
			formData.append('customer_email', userData.email);
			formData.append('customer_phone', userData.phone);

			axios
				.get('https://api.akallivilla.gr/sanctum/csrf-cookie', {
					withCredentials: true,
				})
				.then((response) => {
					axios
						.post(
							'https://api.akallivilla.gr/api/bookings',
							formData
						)
						.then((response) => {
							if (response.status === 200) {
								handleClick('next');
							}
						})
						.catch(function (error) {
							history('/fail');
						});
				});
		} else if (check === true) {
			var formData = new FormData();
			console.log('222');
			formData.append('check_in', check_in.check_in);
			formData.append('check_out', check_out.check_out);
			formData.append('guests', person.persons);
			formData.append('room_id', activeRoom);
			formData.append('price', price);
			formData.append('customer_first_name', userData.first_name);
			formData.append('customer_last_name', userData.last_name);
			formData.append('customer_email', userData.email);
			formData.append('customer_phone', userData.phone);
			formData.append(
				'country_code',
				parsePhoneNumber(userData['phone']).country
			);
			if (payment === 'Card30') {
				formData.append('price_option', 'preprice');
			} else {
				formData.append('price_option', 'fullprice');
			}
			const response = axios
				.post(
					'https://api.akallivilla.gr/api/viva_url_generate',
					formData
				)

				.then((res) => {
					setTimeout(
						() => window.open(res.data.viva_wallet_url),
						1000
					);
				})
				.then((res) => window.open(res.data.viva_wallet_url, '_blank'));
		}

		// axios.defaults.headers['X-CSRF-Token'] = response.data;

		// axios.defaults.xsrfCookieName = 'csrftoken';
		// axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
	};
	return (
		<div className="container flex justify-around md:mt-4 mb-8">
			<button
				onClick={() => handleClick()}
				className={`bg-white text-slate-400 uppercase text-base px-2 rounded-xl font-semibold cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out ${
					currentStep === 1 ? 'opacity-50 cursor-not-allowed' : ''
				}`}
			>
				Back
			</button>
			<button
				onClick={
					currentStep === steps.length - 1
						? payment !== 'Bank'
							? () => submitForm()
							: () => submitForm()
						: () => handleClick('next')
				}
				className="bg-cyan-300 text-white uppercase text-base px-4 rounded-xl font-semibold cursor-pointer  hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out"
			>
				{currentStep === steps.length - 1
					? payment !== 'Bank'
						? 'Pay'
						: 'Confirm'
					: 'Next'}
			</button>
		</div>
	);
}
