import React, { useRef, useState, useEffect } from 'react';
import Hero from '../components/components_available/hero';
import { UseContextProvider } from '../context/stepperContext';
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import CancelPolicy from '../components/cancelPolicy';
import RoomInfo from '../components/RoomInfo';
import axios from 'axios';
export default function Error() {
	const location = useLocation();

	const [display, setDisplay] = useState(false);
	const firstItemRef = useRef(null);
	const search = useLocation().search;

	const id = new URLSearchParams(search).get('t');
	useEffect(() => {
		const formData = new FormData();
		formData.append('order_id', new URLSearchParams(search).get('s'));
		formData.append('payment_status', 'failed');
		const response = axios.post(
			'https://api.akallivilla.gr/api/viva_payment_status',
			formData
		);
	}, []);
	return (
		<>
			<Navbar height={100} />
			<UseContextProvider>
				<div
					style={{
						height: 1220,
						width: '100%',
						marginTop: -250,
						position: 'relative',
						zIndex: 0,
						backgroundImage:
							'linear-gradient(to bottom, rgba(0,0,0,0.1)  30%, rgba(0,0,0,1)),url("/images/WL200396.jpg")',
						backgroundSize: 'cover',
						backgroundPosition: 'center bottom',
						backgroundAttachment: 'fixed',
					}}
				>
					<div className="centered left-1/2 md:text-4xl h-1/2 mt-32 bg-white text-2xl md:w-2/4 w-full text-center  pb-2 shadow-xl rounded-2xl">
						<div className="container mt-48">
							<div className="flex flex-col items-center">
								<div>
									<img
										src="../images/icons8-blocked-64.png"
										alt="completed"
										width={100}
									/>
								</div>
								<div className="mt-3 text-xl font-semibold uppercase text-red-400">
									Oops something went wrong with your payment
									or book!
								</div>
								<div className="text-lg font-semibold text-gray-500">
									Try again or contact us
								</div>

								<a className="mt-10" href="/user/dashboard">
									<Link
										to="/"
										className="h-12 px-5 text-red-400 transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-red-400 hover:text-white"
									>
										Back
									</Link>
								</a>
							</div>
						</div>
					</div>
				</div>
				<RoomInfo />
				<CancelPolicy ref={firstItemRef} setDisplay={setDisplay} />
			</UseContextProvider>
			<Footer style={{ marginTop: 0 }} />
		</>
	);
}
