import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Card(props) {
	const [imageHover, setimageHover] = useState(false);
	return (
		<div
			style={{
				paddingLeft: 32,
				paddingRight: 32,
				position: 'relative',
			}}
			className="h-full md:mt-0 mt-32 "
		>
			<img
				onMouseEnter={() => setimageHover(true)}
				onMouseLeave={() => setimageHover(false)}
				src={props.image}
				alt="akali from the sky"
				width={1200}
				style={{
					borderRadius: 24,
					filter: imageHover ? 'brightness(50%)' : '',
					transition: 'filter 1s ease-out',
				}}
			/>
			<p
				onMouseEnter={() => setimageHover(true)}
				onMouseLeave={() => setimageHover(false)}
				className="md:block hidden"
				style={{
					cursor: 'default',
					opacity: imageHover ? 1 : 0,
					position: 'absolute',
					top: 0,
					padding: 64,
					color: 'white',
					fontWeight: 600,
					lineHeight: 1.7,
					fontFamily: 'Inconsolata',
					transition: 'opacity 1s ease-out',
				}}
			>
				{props.paragraph}
			</p>
			<p
				className="md:hidden block text-center mt-2"
				style={{
					cursor: 'default',
					opacity: 1,
					color: 'black',
					fontWeight: 400,
					lineHeight: 1,
					fontFamily: 'Inconsolata',
					transition: 'opacity 1s ease-out',
				}}
			>
				{props.paragraph}
			</p>
			<Link
				className="md:text-white text-black md:block hidden"
				to="/gallery"
				onMouseEnter={() => setimageHover(true)}
				onMouseLeave={() => setimageHover(false)}
				style={{
					cursor: 'pointer',
					opacity: imageHover ? 1 : 0,
					position: 'absolute',
					bottom: 40,
					left: 100,
					textAlign: 'center',
					padding: 16,
					borderRadius: 12,
					marginLeft: 'auto',
					marginRight: 'auto',
					color: 'white',
					fontWeight: 600,
					lineHeight: 1.7,
					fontFamily: 'Inconsolata',
					transition: 'opacity 1s ease-out',
					border: '1px solid gray',
				}}
			>
				See More
			</Link>
		</div>
	);
}
