import React, { useState, useEffect } from 'react';
import Stepper from '../stepper/stepper';
import { UseContextProvider } from '../../context/stepperContext';
import StepperControl from '../stepper/stepperControl';
import Account from './account';
import Preview from './preview';
import AvailableRoom from './availableRoom';
import { useStepperContext } from '../../context/stepperContext';
import Final from './final';
import PaymentMethod from './paymentMethod';
export default function Hero({ start, end, persons, setDisplay, scroll,activeRoom,setActiveRoom }) {
	const [currentStep, setCurrentStep] = useState(1);
	const [startDate, setStartDate] = useState(start);
	const [endDate, setEndDate] = useState(end);
	
	const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
	const steps = [
		'Dates',
		'Available Rooms',
		'Personal Details',
		'Payment Method',
		'Preview',
	];
	const displayStep = (step) => {
		switch (step) {
			case 1:
				return <AvailableRoom activeRoom={activeRoom} setActiveRoom={setActiveRoom}/>;
			case 2:
				return <Account />;
			case 3:
				return <PaymentMethod />;
			case 4:
				return <Preview setDisplay={setDisplay} activeRoom={activeRoom}/>;
			case 5:
				return <Final />;
		}
	};

	const { check_in, setCheckIn } = useStepperContext();
	const { check_out, setCheckOut } = useStepperContext();
	const { person, setPerson } = useStepperContext();
	useEffect(() => {
		const offset = startDate.getTimezoneOffset();
		const offset2 = endDate.getTimezoneOffset();
		let startDate2 = new Date(startDate.getTime() - offset * 60 * 1000);
		let endDate2 = new Date(endDate.getTime() - offset2 * 60 * 1000);
		setCheckIn({
			check_in,
			['check_in']: startDate2.toISOString().split('T')[0],
		});
		setCheckOut({
			check_out,
			['check_out']: endDate2.toISOString().split('T')[0],
		});
		setPerson({ persons });
	}, []);
	const handleClick = (direction) => {
		let newStep = currentStep;
		direction === 'next' ? newStep++ : newStep--;
		newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
	};
	return (
		<>
			<div
				style={{
					height: 1220,
					width: '100%',
					marginTop: -250,
					position: 'relative',
					zIndex: 0,
					backgroundImage:
						'linear-gradient(to bottom, rgba(0,0,0,0.1)  30%, rgba(0,0,0,1)),url("/images/WL200396.jpg")',
					backgroundSize: 'cover',
					backgroundPosition: 'center bottom',
					backgroundAttachment: 'fixed',
				}}
			>
				<div className="centered left-1/2 md:text-4xl h-1/2 md:mt-32 mt-16 bg-white text-xl w-full md:w-3/4 text-center  pb-2 shadow-xl rounded-2xl">
					<div className="container horizontal mt-1">
						<Stepper steps={steps} currentStep={currentStep} />

						<div className="md:mt-10 mt-14 md:p-10 p-0">
							{displayStep(currentStep)}
						</div>
					</div>
					{currentStep !== steps.length && (
						<StepperControl
						activeRoom={activeRoom}
							handleClick={handleClick}
							currentStep={currentStep}
							steps={steps}
						/>
					)}
				</div>
				<button
					className="color-black h-16 w-16 bottom-10  mx-auto block absolute"
					style={{
						background:
							'url(../images/icons8-right-arrow-64.png) center center no-repeat',
						transform: 'translateX(-50%) rotate(90deg)',
						border: '1px solid #787878',
						borderRadius: '50%',
						left: '50%',
					}}
					onClick={() =>
						document
							.getElementById('roomInfo')
							.scrollIntoView({ behavior: 'smooth' })
					}
				></button>
			</div>
		</>
	);
}
