import React from 'react';
import './css/carousel.css';
import FancyCard from './fancyCard';

export default function MiniCarousel() {
	return (
		<div className="h-full text-center lg:mb-64 mb-32 mt-32 z-0">
			<h2 className="text-2xl" style={{ fontFamily: 'Inconsolata' }}>
				Akalli Villa Services
			</h2>
			<div
				className=" mt-8  grid xl:grid-cols-5 md:grid-cols-2 grid-cols-1  mr-auto ml-auto  h-full mb-32"
				style={{ fontFamily: 'Inconsolata', fontSize: 16 }}
			>
				<div className="relative xl:-top-24 top-0 mt-12 xl:mt-0">
					<FancyCard
						path="images/icons8-television-64.png"
						title="Electronics"
						paragraph="Satellite tv, wifi and air conditioning are included in bedrooms and at livingroom too."
					/>
				</div>
				<div className="relative top-0 mt-12 xl:mt-0">
					<FancyCard
						path="images/icons8-time-50.png"
						title="Check-in/Check-out"
						paragraph={`Check-in : 3:00 PM`}
						paragraph2="Check out : 11:00 AM"
					/>
				</div>
				<div className="relative xl:top-24 top-0 mt-12 xl:mt-0">
					<FancyCard
						path="images/icons8-cleaning-64(2).png"
						title="Cleaning"
						paragraph="Cleaning service is not provited during your stay.We are taking all the safety measures against SARS covid 19. Following all the local instructions."
					/>
				</div>
				<div className="mt-12 xl:mt-0">
					<FancyCard
						path="images/icons8-car-50(1).png"
						title="Transfer"
						paragraph="Need help for your transfer? We can arrange it for you upon request. "
					/>
				</div>
				<div className="relative xl:-top-24 top-0 mt-12 xl:mt-0">
					<FancyCard
						path="images/icons8-parking-64.png"
						title="Parking"
						paragraph="Free parking area at the street."
					/>
				</div>
			</div>
		</div>
	);
}
