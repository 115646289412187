import React, { useState, useEffect } from 'react';
import GalleryPerDesc from './galleryPerDesc';
import ImageModal from './imageModal';

export default function GallerySection({ title, images }) {
	const [grid, setGrid] = useState(Math.round(images.length / 4));
	return (
		<div className="mt-32 text-center">
			<h3
				className="text-2xl text-gray-400"
				style={{ fontFamily: 'Inconsolata' }}
			>
				Gallery Section
			</h3>
			{images.length > 0
				? images.map((image, index) => {
						return (
							<div key={image + index}>
								<GalleryPerDesc images={image} />
							</div>
						);
				  })
				: ''}
		</div>
	);
}
